import { useEffect, useMemo, useRef } from 'react';
import { BarcodeCapture, BarcodeCaptureListener, BarcodeCaptureSession } from '@scandit/web-datacapture-barcode';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { useToast } from '../Toast';
import { useScanditSDK } from './use-scandit-sdk.hook';

export type ScanditScannerProps = {
  onScan: (data: string) => void;
};
export default function ScanditScanner({ onScan }: ScanditScannerProps) {
  const host = useRef<HTMLDivElement | null>(null);
  const { loaded, sdk } = useScanditSDK();
  const translations = useTranslations();
  const { displayToast } = useToast();

  const handleScan = useMemo<BarcodeCaptureListener>(
    () => ({
      didScan: async (_: BarcodeCapture, session: BarcodeCaptureSession) => {
        if (session.newlyRecognizedBarcode?.data) {
          await sdk.enableScanning(false);
          onScan(session.newlyRecognizedBarcode.data);
          setTimeout(async () => sdk.enableScanning(true), 1000);
        }
      },
    }),
    [onScan, sdk],
  );

  useEffect(() => {
    async function onMount(): Promise<void> {
      if (loaded && host.current) {
        sdk.connectToElement(host.current);
        try {
          await sdk.enableCamera(true);
        } catch (e) {
          displayToast('error', translations('camera_devices_permission_error'));
        }
        await sdk.enableScanning(true);
        sdk.addBarcodeCaptureListener(handleScan);
      }
    }
    void onMount();

    return () => {
      if (loaded) {
        void sdk.enableScanning(false);
        sdk.removeBarcodeCaptureListener(handleScan);
        sdk.detachFromElement();
      }
    };
    // eslint-disable-next-line
  }, [loaded, handleScan, sdk]); // FYI: translations in deps causes endless re-rendering

  return <div ref={host} />;
}
