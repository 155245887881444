import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import invariant from 'tiny-invariant';

import { MainPageContent, PageHeading, Table } from '../../../common/components';
import { DEFAULT_PAGINATED_TABLE_LIMIT } from '../../../common/constants/common';
import { formatDateTime } from '../../../common/utils';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useRestaurantInventoryCounts } from '../../../services/inventory-counts';
import { InventoryCountsInvoice } from './components/InventoryCountsInvoice/InventoryCountsInvoice';
import { InventoryCountsStatusUpdate } from './components/InventoryCountsStatusUpdate/InventoryCountsStatusUpdate';
import { InventoryCountResponse } from './models/inventory-count-response';
import { getInventoryCountInventory, getInventoryCountTotalLoss } from './utils/inventory-counts.helper';

export const InventoryCounts = () => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const translations = useTranslations();
  invariant(restaurantId, 'restaurantId is not set within the route');
  const [page, setPage] = useState(1);

  const {
    data: inventoryCounts,
    isLoading: isLoadingInventoryCounts,
    isFetching: isFetchingInventoryCounts,
  } = useRestaurantInventoryCounts(restaurantId, page, DEFAULT_PAGINATED_TABLE_LIMIT);

  const columns = useMemo(
    () => [
      {
        Header: translations('inventory_counts_table_id'),
        accessor: 'id' as const,
      },
      {
        Header: translations('inventory_counts_table_date'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<InventoryCountResponse>) => formatDateTime(new Date(original.createdAt)),
      },
      {
        Header: translations('inventory_counts_table_status'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<InventoryCountResponse>) => (
          <InventoryCountsStatusUpdate inventoryCountId={original.id} status={original.status} />
        ),
      },
      {
        Header: translations('inventory_counts_table_inventory'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<InventoryCountResponse>) => getInventoryCountInventory(original),
      },
      {
        Header: translations('inventory_counts_table_total_loss'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<InventoryCountResponse>) => getInventoryCountTotalLoss(original),
      },
      {
        Header: translations('inventory_counts_table_invoice'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<InventoryCountResponse>) => (
          <InventoryCountsInvoice inventoryCountId={original.id} invoiceFileUrl={original.invoiceFileUrl} />
        ),
      },
    ],
    [translations],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('inventory_counts_header')}</PageHeading>
      <Table
        data={inventoryCounts?.items || []}
        columns={columns}
        isLoading={isLoadingInventoryCounts || isFetchingInventoryCounts}
        paginationEnabled
        page={page}
        onPageChanged={setPage}
        totalPages={inventoryCounts?.totalPages}
      />
    </MainPageContent>
  );
};
