import { InventoryCountStatus } from '../models/enums/inventory-count-status.enum';
import { InventoryCountResponse } from '../models/inventory-count-response';
import { INVENTORY_COUNT_EMPTY_INVENTORY_TABLE_VALUE } from './inventory-counts.consts';

export const getInventoryCountStatusTranslationKey = (status: InventoryCountStatus) => {
  switch (status) {
    case InventoryCountStatus.INVENTORY_IN_PROGRESS:
      return 'inventory_counts_inventory_status_inventory_in_progress';
    case InventoryCountStatus.INVENTORY_FINISHED:
      return 'inventory_counts_inventory_status_inventory_finished';
    case InventoryCountStatus.INVENTORY_REJECTED:
      return 'inventory_counts_inventory_status_inventory_rejected';
    case InventoryCountStatus.UNDER_REVIEW:
      return 'inventory_counts_inventory_status_under_review';
    case InventoryCountStatus.INVOICE_OPEN:
      return 'inventory_counts_inventory_status_invoice_open';
    case InventoryCountStatus.INVOICE_AVAILABLE:
      return 'inventory_counts_inventory_status_invoice_available';
    case InventoryCountStatus.INVOICE_PAID:
      return 'inventory_counts_inventory_status_invoice_paid';
    case InventoryCountStatus.INVOICE_REJECTED:
      return 'inventory_counts_inventory_status_invoice_rejected';
    case InventoryCountStatus.INVENTORY_DELETED:
      return 'inventory_counts_inventory_status_inventory_deleted';
    default: {
      const exhaustiveCheck: never = status;
      throw new Error(exhaustiveCheck);
    }
  }
};

export const getInventoryCountTotalLoss = (inventoryCount: InventoryCountResponse) => {
  if (inventoryCount.status === InventoryCountStatus.INVENTORY_IN_PROGRESS) {
    return INVENTORY_COUNT_EMPTY_INVENTORY_TABLE_VALUE;
  } else {
    if (inventoryCount.actualStock !== null && !!inventoryCount.shouldStock) {
      const lostRate = (
        ((inventoryCount.shouldStock - inventoryCount.actualStock) / inventoryCount.shouldStock) *
        100
      ).toFixed(2);
      return `${lostRate}% (${inventoryCount.shouldStock - inventoryCount.actualStock} lost)`;
    } else {
      return INVENTORY_COUNT_EMPTY_INVENTORY_TABLE_VALUE;
    }
  }
};

export const getInventoryCountInventory = (inventoryCount: InventoryCountResponse): string => {
  if (inventoryCount.shouldStock === null || inventoryCount.actualStock === null) {
    return INVENTORY_COUNT_EMPTY_INVENTORY_TABLE_VALUE;
  }
  return `${inventoryCount.shouldStock}/${inventoryCount.actualStock}`;
};
